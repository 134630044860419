import React from 'react';
import { Button, Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { useNavigate, useParams } from 'react-router-dom'
import { MdDelete, MdEdit } from 'react-icons/md';

import * as codegenapi from '../../graphql/API';
import { IGQLClient } from '../../client/gqlts';

interface IProps {
    gqlClient: IGQLClient,
    studies: codegenapi.Study[] | undefined,
    user: any,
}

const StudyList = (props: IProps): JSX.Element => {
    const navigate = useNavigate();

    const { studyid } = useParams();

    return (
        <Flex>
            <h1>Studies</h1>
            <Loader variation="linear" style={{ display: props.studies && 'none' }} />
            {
                props.studies &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">&nbsp;</TableCell>
                            <TableCell as="th">Subjects</TableCell>
                            {
                                props.user
                                && props.user.groups.includes('study:admin')
                                &&
                                <>
                                    <TableCell as="th">Form types</TableCell>
                                    <TableCell as="th">Activity types</TableCell>
                                </>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.studies.length > 0 && props.studies.map((study) => (
                                <TableRow
                                    key={study.studyid}
                                    style={{ backgroundColor: study.studyid === studyid ? 'lightcyan' : 'white' }}
                                >
                                    <TableCell>
                                        <Button
                                            onClick={() => navigate(`./${study.studyid}`)}
                                        >
                                            {study.name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            className="table-action-button"
                                            onClick={() => navigate(`/studies/${study.studyid}/subjects`)}
                                        >
                                            {!study.subjects ? 0 : study.subjects.length}
                                        </Button>
                                    </TableCell>
                                    {
                                        props.user
                                        && props.user.groups.includes('study:admin')
                                        &&
                                        <>
                                            <TableCell>
                                                <Button
                                                    className="table-action-button"
                                                    onClick={() => navigate(`/studies/${study.studyid}/formtypes`)}
                                                >
                                                    {!study.formtypes ? 0 : study.formtypes.length}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    className="table-action-button"
                                                    onClick={() => navigate(`/studies/${study.studyid}/activitytypes`)}
                                                >
                                                    {!study.activitytypes ? 0 : study.activitytypes.length}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => navigate(`/studies/${study.studyid}/edit`, { 
                                                        state: { siteid: study.siteid } 
                                                    })}
                                                >
                                                    <MdEdit />
                                                </Button>
                                                <Button
                                                    onClick={() => props.gqlClient.gqlRemoveStudy(study.siteid, study.studyid)}
                                                >
                                                    <MdDelete />
                                                </Button>
                                            </TableCell>
                                        </>
                                    }
                                </TableRow>
                            ))
                        }
                        {
                            (!props.studies || props.studies.length === 0) &&
                            <TableRow key='empty'>
                                <TableCell colSpan={6}><i>No studies yet!</i></TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table >
            }
        </Flex>
    )
}

export default StudyList;
